import React from "react";
import ReactDOM from "react-dom/client";
import { IconButton, Button, Container, Grid, Paper, Typography, useTheme, useMediaQuery, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, ListItem, List, ListItemText, Tooltip, Tabs, Tab, TextField, styled, Chip, CircularProgress, Alert, Snackbar, duration, createTheme, ThemeProvider, TableContainer, TableCell, TableBody, Table, TableRow, TableHead } from '@mui/material';
import { Excalidraw, exportToBlob } from "@excalidraw/excalidraw";
import { useState, useEffect } from 'react';
import { createClient } from '@deepgram/sdk';
import Vapi from '@vapi-ai/web';
import axios from 'axios';
import io from 'socket.io-client';
import MicIcon from '@mui/icons-material/Mic';
import MicOffIcon from '@mui/icons-material/MicOff';
import SyncIcon from '@mui/icons-material/Sync';
import StopIcon from '@mui/icons-material/Stop';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import ChatIcon from '@mui/icons-material/Chat';
import Avatar from '@mui/material/Avatar';
import ReactGA from "react-ga4";
import * as Frigade from '@frigade/react';
import { useFlow } from '@frigade/react';
import logo from './new_logo.svg';

const _ = require('lodash');


ReactGA.initialize([
  {
    trackingId: "G-KEJZXKN80Q",
  },
]);
ReactGA.send({ hitType: "pageview", page: "/", title: "hit" });

// TODO: move to process.env.DEEPGRAM_API_KEY
const deepgram = createClient('55dba888de36fd0a971cd6619beeb4ac9235c725');
var firstSilenceTimestamp = 0;
const vapi = new Vapi('c6a21d89-0ffd-45b2-9ad4-28a1fd2708f8')
var callId;
var socket;

var proxyEndpoint = 'https://lit-dusk-21105-d4d3c182eb10.herokuapp.com'
var wsEndpoint = 'wss://lit-dusk-21105-d4d3c182eb10.herokuapp.com'
var vapiEnvId = '3e10d61c-25cb-4da0-9f4c-f721d4fdf8ae' // prod
var env = String(process.env.REACT_APP_ENV)

if(env === 'staging') {
  proxyEndpoint = 'https://staging-proxy-6f8d86796ff2.herokuapp.com'
  wsEndpoint = 'wss://staging-proxy-6f8d86796ff2.herokuapp.com'
  vapiEnvId = '02cb35d0-dd7d-47d1-893f-8367b96aa265'
} else if (env === 'development') {
  proxyEndpoint = 'http://localhost:5000'
  wsEndpoint = 'ws://localhost:5000'
  vapiEnvId = '49069a35-62f2-4c72-b3d1-63d59f93a324'
}

var stopInvoked = false;
const emptyInterviewObject = {
  state: 'none',
  messages: [],
  context: [],
  mute: false,
  metadata: {
    email_case_submissions: -1
  },
  scorecard: [],
  stage: ''
}
const StateToCueMap = {
  'none': 'Get started by clicking on the "Start Interview" button above.',
  "FirstResponse": "Let Steve know you can hear him ok. He's a bit hard of hearing, so you may have to tell him twice.",
  "ProblemAffirmation": "Tell Steve you want to proceed if you feel comfortable with the problem.",
  "DrawOnWhiteboard": 'Complete the diagram on the whiteboard to solve the given problem. You can talk to Steve if you get stuck. Click the "get whiteboard feedback" button to get whiteboard feedback.'
}

 // Override Material UI Theme Font
const customTheme = createTheme({
  typography: {
    fontFamily: 'Figtree, sans-serif',
  },
});

// TODO: SWITCH BACK, only for staging testing
var interviewDuration = 600;
const urlParams = new URLSearchParams(window.location.search);
const interviewTypeQueryParam = urlParams.get('interview_type');
if (interviewTypeQueryParam === 'residency' || interviewTypeQueryParam === 'phd_psych' || interviewTypeQueryParam === 'therapy') {
  interviewDuration = 600;
}

const App = () => {
    const [excalidrawAPI, setExcalidrawAPI] = useState(null);
    const [interviewStarted, setInterviewStarted] = useState(false); 
    const [waitlistIsOpen, setWaitlistIsOpen] = React.useState(false);
    const [email, setEmail] = React.useState('');
    // const [callId, setCallId] = useState(0)
    const [interview, setInterview] = useState(emptyInterviewObject);
    const [openHelp, setOpenHelp] = useState(false);
    const [updated, setUpdated] = useState(false);
    const [loadingDiagramFeedback, setLoadingDiagramFeedback] = useState(false);
    const [whiteboardState, setWhiteboardState] = useState({});
    const [micEnabled, setMicEnabled] = useState(false);
    const [visualState, setVisualState] = useState('');
    const [firstMobileScreenDone, setFirstMobileScreenDone] = useState(false);
    const [scorecardModalOpen, setScorecardModalOpen] = useState(false);
    const [emailCaseDraft, setEmailCaseDraft] = useState('');
    const [emailSubmitPending, setEmailSubmitPending] = useState(false);
    const [tutorialStatus, setTutorialStatus] = useState('not_started');
    const [interviewStage, setInterviewStage] = useState('Introduction');
    const [emailCaseImage, setEmailCaseImage] = useState('');
    const [showWriteSection, setShowWriteSection] = useState(false);
    const [endTutorialModalOpen, setEndTutorialModalOpen] = useState(false);

    // Waitlist
    const [subscriptionSuccessSnackOpen, setSubscriptionSuccessSnackOpen] = React.useState(false);
    const [subscriptionFailSnackOpen, setSubscriptionFailSnackOpen] = React.useState(false);
    const [initialSubmissionDone, setInitialSubmissionDone] = useState(false);
    const [endTutorialModalOpenFirstBtnClicked, setEndTutorialModalOpenFirstBtnClicked] = useState(false);

    // Tabs
    const [tabValue, setTabValue] = React.useState(0);

    // Mobile home screen mode
    const [startMobileDemo, setStartMobileDemo] = React.useState(false);    

    // Material UI responsive layout settings
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const excalidrawStyle = {
        minHeight: '60vh',
        width: '100%',
        background: '#050505', // Change the background color
    };
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    // Timer
    const [timer, setTimer] = React.useState(interviewDuration);

    useEffect(() => {
      let interval = null;
      if (interviewStarted) {
        console.log('starting timer')
        interval = setTimeout(() => {
          if(timer > 0) {
            setTimer(timer => timer - 1)
          }
        }, 1000);
      }

      return () => {
          if (interval !== null && timer <= 0) {
              clearTimeout(interval);
          }
      };
    }, [interviewStarted, timer]);    
    
    const { flow } = useFlow("flow_rlk6j4gk")
    var currentStepIndex = 0
    if(flow !== undefined) {
      console.log(flow)
      currentStepIndex = flow.getCurrentStepIndex()
      if(tutorialStatus !== 'complete' && flow.isCompleted) {
        setTutorialStatus('complete')
      }
    }

    const handleOpenWaitlist = () => {
      setWaitlistIsOpen(true);
    }

    const handleCloseWaitlist = () => {
      setWaitlistIsOpen(false);
    }

    const handleCloseSuccessSnack = () => {
      setSubscriptionSuccessSnackOpen(false);
    }

    const handleCloseFailSnack = () => {
      setSubscriptionFailSnackOpen(false);
    }

    const handleEmailCaseSubmission = () => {
      console.log('handleEmailCaseSubmission')
      setEmailSubmitPending(true)
      setInitialSubmissionDone(true)

      const buffering_reses = [
        "Hang on, taking a look at your submission now.",
        "Let me review your submission real quick.",
        "I'm currently assessing the details of your submission.",
        "Give me a moment to go through your response.",
        "I'm diving into your submission as we speak.",
        "Examining your draft carefully, one moment please.",
        "Just opening your response to take a closer look.",
        "Allow me a few minutes to evaluate your response.",
        "Reviewing the contents of your email submission.",
        "Analyzing your submission, please hold."
      ];


      const buffering_res = buffering_reses[Math.floor(Math.random() * buffering_reses.length)];
      if(interview.metadata.email_case_submissions <= 3) {
        vapi.send({
          type: "add-message",
          message: {
            role: "system",
            content: "parrot_: " + buffering_res,
          },
        });        
      }

      axios.post(`${proxyEndpoint}/virtual_assistant_email_case`, {
        emailDraft: emailCaseDraft,
        callId: callId
      })
      .then((response) => {
        console.log('Email case submission success:', response.data);
        setEmailSubmitPending(false)
      })
      .catch((error) => {
        console.error('Email case submission error:', error);
        // Handle error scenario, e.g., showing an error message to the user
        setEmailSubmitPending(false)
      });
    }
    const validateEmail = (email) => {
      var re = /\S+@\S+\.\S+/;
      return re.test(email);
    }    

    const handleJoinWaitlist = async () => {
      const emailValid = validateEmail(email)
      if(!emailValid) {
        setSubscriptionFailSnackOpen(true)
        return
      }

      try {
        const response = await axios.post(proxyEndpoint + '/join_waitlist', {
          subscription: { email: email }
        });
        if(!response.data.result) {
          setSubscriptionFailSnackOpen(true);  
        } else {
          handleCloseWaitlist();
          setSubscriptionSuccessSnackOpen(true);
          setWaitlistIsOpen(false);
        }
      } catch (error) {
        setSubscriptionFailSnackOpen(true);
      }
      
    }

    const handleEmailChange = (event) => {
      setEmail(event.target.value);
    };

    const handleCloseSubscriptionFailSnack = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
  
      setSubscriptionFailSnackOpen(false);
    };

    const handleCloseSubscriptionSuccessSnack = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
  
      setSubscriptionSuccessSnackOpen(false);
    };

    const handleChangeTab = (event, newValue) => {
      setTabValue(newValue);
    };


    // UI
    const Item = styled(Paper)(({ theme }) => ({
      backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
      fontFamily: 'figtree, sans-serif', // Set the font family to "figtree"
      ...theme.typography.body2,
      padding: theme.spacing(1),
      textAlign: 'center',
      color: theme.palette.text.secondary,
      marginRight: '20px'
    }));

    // Help button
    const handleHelpOpen = () => {
      setOpenHelp(true);
    }
  
    const handleHelpClose = () => {
      setOpenHelp(false);
    };

    const handleStartMobileDemo = () => {
      setStartMobileDemo(true);
    }

    if(excalidrawAPI !== null && !updated) {
        fetch('prefill.excalidraw.json')
        .then(response => response.json())
        .then(data => {
          excalidrawAPI.updateScene({elements: data.elements, appState: data.appState});
          excalidrawAPI.scrollToContent(data.elements, {
              fitToContent: true,
              animate: false,
          });
          setUpdated(true);
          const appState = excalidrawAPI.getAppState();
          const elements = excalidrawAPI.getSceneElements();
          if (!elements || !elements.length) {
              return
          }          

          exportToBlob({
              elements,
              appState: {
                  ...appState,
                  exportWithDarkMode: false,
              },
              files: excalidrawAPI.getFiles(),
              maxWidthOrHeight: 1000
          })
          .catch((error) => {
              console.error('Error:', error);
          });
        })
    }

    const initWebsocketConnection = (callId) => {
      let clientType = 'desktop'
      if (isMobile) {
        clientType = 'mobile'
      }
      var interviewType = 'virtual_assistant_email_case'
      const urlParams = new URLSearchParams(window.location.search);
      const interviewTypeQueryParam = urlParams.get('interview_type');
      if (interviewTypeQueryParam) {
        interviewType = interviewTypeQueryParam;
      }

      console.log('initWebsocketConnection')
      const socket = io(wsEndpoint, {
        withCredentials: true,
        transports: ["websocket"]        
      });
      socket.on('connect', () => {
        console.log('Websocket connected');
      });
      socket.on('done', (data) => {
        if(data.callId === callId) {
          setTimeout(() => {
            handleStopInterview()
          }, 10000)
        }
      })

      socket.on('disconnect', () => {
        console.log('Websocket disconnected');
      });

      console.log(interviewType);

      socket.emit('register', {
        callId: callId,
        clientType: clientType,
        interviewType: interviewType,
      });
      
      socket.on('update', (interview) => {
        console.log('update', interview.metadata.callId, callId)
        console.log(interview)
        if(interview.metadata.callId === callId) {
          setInterview(interview);

          if(interview.stage !== null && interview.stage.label !== null) {
            setInterviewStage(interview.stage.label)
          }
          if(interview.stage !== null && interview.stage.image !== null) {
            if( (interviewType === 'virtual_assistant' || interviewType === 'virtual_assistant_email_case') && interview.stage.label === 'Email Case Question') {
              setEmailCaseImage('email_case.jpg')
            } else if ( (interviewType === 'virtual_assistant' || interviewType === 'virtual_assistant_email_case') && interview.stage.label !== 'Email Case Question') {
              setEmailCaseImage('')
            }
          }        
  
          if(interview.stage === 'end') {
            setTimeout(() => {
              console.log('Interview has ended. Redirecting to scorecard...');
              navigator.mediaDevices.getUserMedia({ audio: true })
                .then(stream => {
                  stream.getTracks().forEach(track => track.stop());
                  console.log('User mic input turned off.');
                })
                .catch(error => console.error('Error turning off mic:', error));
              handleStopInterview()
            }, 15000);
          }
        }
      });

      socket.on('parrot', (res) => {
        console.log('par', res.callId)
        if(res.callId === callId) {
          console.log('parroting')
          vapi.send({
            type: "add-message",
            message: {
              role: "system",
              content: "parrot_: " + res.res,
            },
          });        
        }
      })

      return socket;
    }

    // const handleStartInterview_Sindarin = () => {
    //   const script = document.createElement("script");
    //   const apiKey = "d935082f-b4d0-4c99-a496-c69bf1b5c249";      
    //   script.src = "https://api.sindarin.tech/PersonaClientPublic?apikey=" + apiKey;      
    //   const personaName = ""
    //   const userId = ""

    //   script.addEventListener("load", async () => {
    //     console.log("persona client loaded");
    //     const personaClient = new window.PersonaClient(apiKey);      
    //     personaClient.init(userId, personaName)      
        
    //   })
    // }

    const handleStartTutorial = () => {
      console.log('handleStartTutorial')
      setTutorialStatus('started');
      setInterviewStage("Introduction")
      setInterview({ ...interview, context: ['These are sample instructions.']})
      setEmailCaseImage('tutorial_case.jpeg')
    }

    const handleStartInterview = () => {
        console.log('handleStartInterview')
        setInterviewStarted(true);
        vapi.start(vapiEnvId)
        .then((data) => {
          // i assume ID is callId
          callId = data['id']
          if(socket === undefined) {
              socket = initWebsocketConnection(callId);
          }                  
        })
        .catch((err) => {console.error(err)})

        vapi.on('speech-start', () => {
            console.log('Speech has started');
            setVisualState('speaking');
        });
        
        vapi.on('speech-end', () => {
            console.log('Speech has ended');
            setVisualState('listening');
        });
        
        vapi.on('call-start', () => {
            console.log('Call has started');            
        });
        
        vapi.on('call-end', () => {
            console.log('Call has stopped');
        });
        
        vapi.on('volume-level', (volume) => {
        });
        
        // Function calls and transcripts will be sent via messages
        vapi.on('message', (message) => {
            console.log(message);
        });
        
        vapi.on('error', (e) => {
            console.error(e)
        });   
    }

    const handleStopInterview = () => {
      console.log('handleStopInterview')
      if(!scorecardModalOpen) {
        vapi.stop()
        // get scorecard
        if(stopInvoked) {
          return 
        }
        stopInvoked = true

        if(interviewType !== 'patient_questionnaire' && interviewType !== 'virtual_assistant_tutorial') {
          console.log('making request')
          axios.post(`${proxyEndpoint}/scorecard`, { callId: callId })
          .then((response) => {
              console.log(response.data)
          })
          .catch((error) => {          
              console.error('Error fetching scorecard:', error);
          });
  
          setScorecardModalOpen(true);        
        }

        if(interviewType === 'virtual_assistant_tutorial') {
          setEndTutorialModalOpen(true)
        }        

        // if(socket != null) {
        //     socket.close();
        //     socket = null;
        // }
        setInterview(emptyInterviewObject)
      }
    }

    const handleSubmitDiagramFeedback = () => {
      console.log('handleSubmitDiagramFeedback')
      setLoadingDiagramFeedback(true);
      const buffering_reses = [
        'Hang on, taking a look at the whiteboard', 
        'One sec, taking a look', 
        'Looking, give me one second.',
        "Bear with me, I'm perusing the whiteboard details.",
        "Just a tick, I'm eyeing the whiteboard content.",
        'Allow me a brief pause to scan the whiteboard.',
        'I need a short moment to digest the whiteboard info.',
        'Let me take a swift glance at the whiteboard.',
        "I'm diving into the whiteboard, hold on.",
        'A quick study of the whiteboard is underway.',
        "I'm zooming in on the whiteboard, stand by.",
        'Peering into the whiteboard, just a moment.',
        "I'm deciphering the whiteboard scribbles, hang tight."
      ];
      const buffering_res = buffering_reses[Math.floor(Math.random() * buffering_reses.length)];
      vapi.send({
        type: "add-message",
        message: {
          role: "system",
          content: "parrot_: " + buffering_res,
        },
      });        
      
      if (!excalidrawAPI) {
          return
      }
      const elements = excalidrawAPI.getSceneElements();
      if (!elements || !elements.length) {
          return
      }

      const appState = excalidrawAPI.getAppState();

      exportToBlob({
          elements,
          appState: {
              ...appState,
              exportWithDarkMode: false,
          },
          files: excalidrawAPI.getFiles(),
          maxWidthOrHeight: 1000
      })
      .then((blob) => {
          const reader = new FileReader();
          reader.readAsDataURL(blob);
          reader.onloadend = () => {
              const base64data = reader.result;
              fetch(`${proxyEndpoint}/get_diagram_feedback`, {
                  method: 'POST',
                  headers: {
                      'Content-Type': 'application/json'
                  },
                  body: JSON.stringify({ image: base64data, callId: callId })
              })
              .then((response) => {
                  setLoadingDiagramFeedback(false);
                  response.json()
                  .catch((err) => {console.error(err)})
              })
              .catch((err) => {console.error(err)})
          }
      }) 
    }

    if (timer <= 0) {
        handleStopInterview()
    }

    const renderFeedback = () => {
      if( initialSubmissionDone && !emailSubmitPending && interview.context.length > 1 && interview.context[interview.context.length-1].startsWith('Feedback:') ) {
        return (
          <div key={0} style={{ display: 'flex', alignItems: 'center', paddingRight: 15, marginBottom: 25 }}>
            <Typography variant="body2" sx={{ fontFamily: 'Mulish', color: '#C6C2D0', fontWeight: 700 }}>
              {interview.context[interview.context.length-1].replace('Feedback:', '')}
            </Typography>
          </div>                                        
        )
      } else {
        return (
          <div key={0} style={{ display: 'flex', alignItems: 'center', paddingRight: 15, marginBottom: 25 }}>
            <Typography variant="body2" sx={{ fontFamily: 'Mulish', color: '#C6C2D0', fontWeight: 700 }}>
              N/A
            </Typography>
          </div>                                        
        )
      }
    }

    const renderContext = () => {
      if(interviewType === 'virtual_assistant_tutorial' && interviewStarted){
        if(interview.messages.length <= 3) {
          return (
            <div key={0} style={{ display: 'flex', alignItems: 'center', paddingRight: 15, marginBottom: 25 }}>
              <Avatar sx={{ bgcolor: 'black', color: 'white', width: 25, height: 25, border: '0.5px solid #4451f6', marginRight: 3 }}>S</Avatar>
              <Typography variant="body2" sx={{ fontFamily: 'Mulish', color: '#C6C2D0', }}>
                Respond to Steve!
              </Typography>
            </div>            
          )
        } else if (interview.messages.length > 3 && !showWriteSection) {
          setTimeout(() => {
            setShowWriteSection(true)
          }, 30000)
          return (
            <div key={0} style={{ display: 'flex', alignItems: 'center', paddingRight: 15, marginBottom: 25 }}>
              <Avatar sx={{ bgcolor: 'black', color: 'white', width: 25, height: 25, border: '0.5px solid #4451f6', marginRight: 3 }}>S</Avatar>
              <Typography variant="body2" sx={{ fontFamily: 'Mulish', color: '#C6C2D0', }}>
                Nice job! Now, read the text on the image that just popped up on the screen. Respond to Steve with what you think.
              </Typography>
            </div>            
          ) 
        } else if (interview.messages.length > 6 && !initialSubmissionDone && showWriteSection) {
          return (
            <div key={0} style={{ display: 'flex', alignItems: 'center', paddingRight: 15, marginBottom: 25 }}>
              <Avatar sx={{ bgcolor: 'black', color: 'white', width: 25, height: 25, border: '0.5px solid #4451f6', marginRight: 3 }}>S</Avatar>
              <Typography variant="body2" sx={{ fontFamily: 'Mulish', color: '#C6C2D0', }}>
                Submit your response in the text input and click "Submit". 
              </Typography>
            </div>            
          ) 
        } else if(initialSubmissionDone){
          return (
            <>
              <div key={0} style={{ display: 'flex', alignItems: 'center', paddingRight: 15, marginBottom: 25 }}>
                <Avatar sx={{ bgcolor: 'black', color: 'white', width: 25, height: 25, border: '0.5px solid #4451f6', marginRight: 3 }}>S</Avatar>
                <Typography variant="body2" sx={{ fontFamily: 'Mulish', color: '#C6C2D0', }}>
                  Revise your response based on Steve's feedback. Click "Re-Submit" when you're done.
                </Typography>
              </div>            
            </>
          )           
        }
      } else {
        console.log('render context')
        if(interview.stage.label === 'Email Case Question') {
          setTimeout(() => {
            setShowWriteSection(true)
          }, 60000)
        }

        var context = []
        interview.context.map((message, index) => {
          console.log(showWriteSection, index, interview.context, interview.context[index+1])
          if(showWriteSection && index + 1 <= interview.context.length -1 && interview.context[index +1].startsWith('Feedback')) {
            context.push(message)
            context.push('Click "Submit" once you are happy with your email draft to get feedback on your submission in order to improve it.')
          } else if(showWriteSection && index == interview.context.length - 1) {
            if(!message.startsWith('Feedback:')) {
              context.push(message)
            }
          } else {
            context.push(message)
          }
        })

        return context.map((message, index) => (
          <div key={index} style={{ display: 'flex', alignItems: 'center', paddingRight: 15, marginBottom: 25 }}>
              <Avatar sx={{ bgcolor: 'black', color: 'white', width: 25, height: 25, border: '0.5px solid #4451f6', marginRight: 3 }}>S</Avatar>
              <Typography variant="body2" sx={{ fontFamily: 'Mulish', color: '#C6C2D0', }}>{message}</Typography>
          </div>
        ))
      }      
    }

    const renderModalTitle = () => {
      if(interviewType === 'virtual_assistant_tutorial') {
        return 'Start Tutorial'
      } else {
        return 'Start Interview'
      }
    }

    const renderIntroDialoguePostInputsEnabledContent = () => {
      if(interviewType === 'virtual_assistant_tutorial' && tutorialStatus === 'not_started') { 
        return (
          <>
            {!micEnabled &&
              <div style={{ color: 'white', fontFamily: 'Mulish', padding: 15 }}>
                This is a tutorial for using Interviewer. Please enable your microphone to talk to Steve, your AI interviewer.
              </div>
            } 
            {micEnabled &&
              <div style={{ color: 'white', fontFamily: 'Mulish', padding: 15 }}>
                Your microphone is enabled! Press "Start Tutorial" to learn how the product works and go through a demo experience before your real interview.
              </div>                            
            }          
          </>         
        )
      } else if(interviewType === 'virtual_assistant_tutorial' && tutorialStatus === 'complete') {
        return (
          <>
            <div style={{ color: 'white', fontFamily: 'Mulish', padding: 15 }}>
              We will now do a <b>practice interview</b> experience which will be the last part of the tutorial. Once you have finished this, we will move onto the real interview when you are ready.
            </div>                                         
          </>
        )
      } else {
        return (
          <>
          {!micEnabled &&
            <div style={{ color: 'white', fontFamily: 'Mulish', padding: 15 }}>
              You will be doing a 10 minute pre-screening interview. Please enable your microphone to talk to Steve, your AI interviewer.
            </div>
          } 
          {micEnabled &&
            <div style={{ color: 'white', fontFamily: 'Mulish', padding: 15 }}>
              Your microphone is enabled! Press the start interview button and our interviewer Steve will read you off a prompt and instructions.
              <br/>
              <br/>
              Watch <a style={{ color: 'white' }} href="https://www.youtube.com/embed/d2wknWa1VVg">this live video</a> instead if you're not in a place to talk.
            </div>                            
          }           
        </>         
        )   
      }
    }    

    function secondsToHumanReadableTime(seconds) {
      const hours = Math.floor(seconds / 3600);
      const minutes = Math.floor((seconds % 3600) / 60);
      const remainingSeconds = seconds % 60;
    
      const formattedTime = `${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
    
      return formattedTime;
    }    

    const getDisplayValue = (idSelector) => {
      if(interviewType !== 'virtual_assistant_tutorial') {
        return 'visible'
      }

      if(tutorialStatus === 'complete') {
        return 'visible'
      }

      switch(idSelector) {
        case 'stage':
          if(currentStepIndex >= 0) {
            return 'visible'
          } else {
            return 'hidden'
          }
        case "instructions":
          if(currentStepIndex >= 1) {
            return 'visible'
          } else {
            return 'hidden'
          }   
        case 'mute-steve':
          if(currentStepIndex >= 2) {
            return 'visible'
          } else {
            return 'hidden'
          }
        case 'email-case-image':
          if(currentStepIndex >= 3) {
            return 'visible'
          } else {
            return 'hidden'
          }
        case 'submission':
          if(currentStepIndex >= 4) {
            return 'visible'
          } else {
            return 'hidden'
          }
        case "feedback":
          if(currentStepIndex >= 5) {
            return 'visible'
          } else {
            return 'hidden'
          }                 
      }
    }

    const handleToggleMute = () => {
      console.log('handleToggleMute')
      socket.emit('mute', callId);
    }

    const enableMic = () => {
      navigator.mediaDevices.getUserMedia({ audio: true })
      .then(function(stream) {
        setMicEnabled(true);
      })
      .catch(function(err) {
        console.log('Microphone permission denied');
      });
    }
    console.log('(isMobile && firstMobileScreenDone) || !isMobile', (isMobile && firstMobileScreenDone) || !isMobile)
    var interviewType = 'virtual_assistant_email_case'
    const urlParams = new URLSearchParams(window.location.search);
    const interviewTypeQueryParam = urlParams.get('interview_type');
    if (interviewTypeQueryParam) {
      interviewType = interviewTypeQueryParam;
    }

    return (
        <>
          <Container>
            <Grid container direction="column" spacing={1}>
              <Grid item xs={12} sm={12}>
                <Dialog open={waitlistIsOpen} onClose={handleCloseWaitlist}>
                  <DialogTitle
                    style={{
                      background: 'black',
                      color: 'white',
                      border: '0.5px solid #5F6368',
                      fontFamily: 'Mulish'
                    }}
                  >Waitlist</DialogTitle>
                  <DialogContent
                    style={{
                      background: 'black',
                      color: 'white',
                      border: '0.5px solid #5F6368',
                      padding: 10,
                      fontFamily: 'Mulish'
                    }}
                  >
                    <DialogContentText style={{
                      fontFamily: 'Mulish',
                      color: 'white',
                      padding: 10
                    }}>
                      <TextField
                          InputProps={{
                            style: {
                              fontFamily: 'Mulish',
                              color: 'white',
                              marginTop: 15,
                            },
                          }}
                          autoFocus
                          margin="dense"
                          id="name"
                          label="Email Address"
                          type="email"
                          fullWidth
                          onChange={handleEmailChange}
                          variant="standard"
                        />                      
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions 
                    style={{
                      background: 'black',
                      color: 'white',
                      border: '0.5px solid #5F6368',
                      fontFamily: 'Mulish'
                    }}
                  >
                    <Button 
                      style={{ color: 'black', background: '#4451f6', borderRadius: 25, fontFamily: 'Mulish' }}                    
                      onClick={handleCloseWaitlist}>Cancel</Button>
                    <Button 
                      style={{ color: 'black', background: '#4451f6', borderRadius: 25, fontFamily: 'Mulish' }}                    
                      onClick={handleJoinWaitlist}>Subscribe</Button>
                  </DialogActions>
                </Dialog>
                <Snackbar
                  open={subscriptionSuccessSnackOpen}
                  autoHideDuration={5000}
                  onClose={handleCloseSubscriptionSuccessSnack}>
                    <Alert onClose={handleCloseSuccessSnack} severity="success" sx={{ width: '100%' }}>
                      You're on the waitlist!
                    </Alert>
                  </Snackbar>
                  <Snackbar
                  open={subscriptionFailSnackOpen}
                  autoHideDuration={5000}
                  onClose={handleCloseSubscriptionFailSnack}>
                    <Alert onClose={handleCloseFailSnack} severity="error" sx={{ width: '100%' }}>
                      Error: e-mail already used or invalid format.
                    </Alert>
                  </Snackbar>
              </Grid>
            </Grid>
          </Container>
          {/* {((isMobile && firstMobileScreenDone) || !isMobile) &&
          <Paper elevation={0} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', zIndex: 9999, background: '#19e539', color: 'black' }}>
            <Typography variant="h6" style={{ cursor: 'pointer', textTransform: 'none' }}>
              <Button onClick={handleOpenWaitlist} style={{ color: 'black', textTransform: 'none', fontSize: 16, fontFamily: 'Mulish' }}>
                {isMobile &&
                  <>
                  Get future access.
                  </>  
                }
                {!isMobile &&
                  <>
                    Get future access and your interview recording.
                  </>
                }
                <span style={{ textDecoration: 'underline', marginLeft: '4px' }}>Join waitlist.</span>
              </Button>
            </Typography>
          </Paper>                        
          } */}
          <Container maxWidth = "xl" className = "whiteboardView" style={{ backgroundColor: '#050505' }}>       
            <div style={{ marginTop: 25 }}>
            <a href="https://techinterviewer.ai">
              <img src={logo} />
            </a>
            </div>              
            <br/>
              <Grid container direction="column" spacing={3}>                  
                  <Grid item container xs={12} style={{ marginBottom: 15 }}>
                    {!isMobile &&
                    <Grid item xs={4} style={{ textAlign: 'left' }}>
                      <div style={{fontSize: 24, fontFamily: 'Mulish'}}>
                        {interviewType === 'patient_questionnaire' &&
                          <>
                          Lupus Patient Check-in
                          </>
                        }
                        {interviewType === 'system_design' &&
                          <>
                            Mock System Design Interview
                          </>
                        }
                        {interviewType === 'virtual_assistant' &&
                          <>
                            Virtual Assistant Pre-Screening Interview
                          </>
                        }
                        {interviewType === 'instacart_biz_ops' &&
                          <>
                            Instacart Business Operations Manager Pre-Screening Interview
                          </>
                        }     
                        {interviewType === 'residency' &&
                          <>
                            Mock Residency Interview
                          </>
                        }                          
                        {interviewType === 'phd_psych' &&
                          <>
                            Mock Clinical Psych PhD Interview
                          </>
                        }    
                        {interviewType === 'therapy' &&
                          <>
                            Therapy Session
                          </>
                        }  
                        {interviewType === 'virtual_assistant_tutorial' && 
                          <>
                          Virtual Assistant Tutorial
                          </>
                        }                                                                                                                  
                        {interviewType === 'swe_with_english_proficiency' &&
                          <>
                            Software Engineer Labeler
                          </>
                        }                                                
                      </div>
                    </Grid>                    
                    }
                    {(firstMobileScreenDone && isMobile) &&
                    <>
                    <Grid item xs={12} style={{ textAlign: 'left', marginBottom: 30, marginTop: 30 }}>
                      <div style={{fontSize: 17, fontFamily: 'Mulish' }}>
                        {interviewType === 'system_design' &&
                          <>
                            Mock System Design Interview
                          </>
                        }
                        {interviewType === 'virtual_assistant' &&
                          <>
                            Virtual Assistant Pre-Screening Interview
                          </>
                        }
                        {interviewType === 'swe_with_english_proficiency' &&
                          <>
                            Software Engineer Labeler
                          </>
                        }                        
                        {interviewType === 'instacart_biz_ops' &&
                          <>
                            Instacart Business Operations Manager Pre-Screening Interview
                          </>
                        }              
                        {interviewType === 'residency' &&
                          <>
                            Mock Residency Interview
                          </>
                        }                                                     
                        {interviewType === 'phd_psych' &&
                          <>
                            Mock Clinical Psych PhD Interview
                          </>
                        }    
                        {interviewType === 'therapy' &&
                          <>
                            Therapy Session
                          </>
                        }            
                        {interviewType === 'virtual_assistant_tutorial' && 
                          <>
                          Virtual Assistant Tutorial
                          </>
                        }                                                        
                      </div>
                    </Grid>                         
                    </>
                    }
                    <Grid item xs={8} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                      <div> 
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                        {!isMobile && 
                          <div style={{ display: 'flex', alignItems: 'center', marginRight: 30, fontSize: 16, fontFamily: 'Mulish', visibility: interviewStarted ? 'visible' : 'hidden', color: '#4451f6', marginBottom: 0, paddingBottom: 0 }}>
                            {visualState === '' &&
                              <div style={{ color: interviewStarted ? 'white' : 'black' }}>
                                Steve is Connecting...
                              </div>
                            }
                            {visualState === 'listening' &&
                              <div>
                                Steve is <b>Listening</b>
                              </div>
                            }
                            {visualState === 'speaking' &&
                              <div>
                                Steve is <b>Speaking</b>
                              </div>
                            }                          
                          </div>
                        }
                          {/* {((isMobile && firstMobileScreenDone) || !isMobile) &&
                          <div style={{ display: 'flex', alignItems: isMobile ? 'left' : 'center', fontFamily: 'Mulish', fontSize: 18, color: 'white', marginRight: isMobile ? 0 : 15, position: isMobile ? 'absolute' : 'relative', left: isMobile ? '2.5vh' : 0 }}>
                            <AccessTimeIcon />
                            <span style={{ fontSize: 18, marginLeft: 5 }}>
                              {secondsToHumanReadableTime(timer)}
                            </span>
                          </div>                          
                          } */}
                          {interviewStarted &&
                            <IconButton onClick={handleStopInterview} style={{
                              background: '#4451f6',
                              color: 'white',
                              borderRadius: 25,
                              fontFamily: 'Mulish',
                              fontSize: 16,
                              marginLeft: 15, // Added to provide spacing between buttons
                            }}>
                              <StopIcon /> Stop/ Score
                            </IconButton>                          
                          }
                          {!isMobile && interviewType === 'system_design' &&
                            <IconButton onClick={handleSubmitDiagramFeedback} disabled={loadingDiagramFeedback} style={{
                              background: '#19E539',
                              color: 'black',
                              borderRadius: 25,
                              fontFamily: 'Mulish',
                              fontSize: 16,
                              marginLeft: 'auto', // Added to move the button to the right
                            }}>
                              {loadingDiagramFeedback ? 'Syncing...' : <><SyncIcon />
                              Sync</>}                          
                            </IconButton>
                          }
                          {((isMobile && firstMobileScreenDone) || !isMobile) &&
                            <IconButton id="mute-steve" onClick={handleToggleMute} style={{
                              background: interview.mute ? 'white' : '#181818',
                              fontSize: 16,
                              color: interview.mute ? 'black' : 'white',
                              borderRadius: 25,
                              fontFamily: 'Mulish',
                              position: isMobile ? 'absolute' : 'relative',
                              right: isMobile ? '2.5vh' : 0,
                              marginLeft: 15, // Added to provide spacing between buttons
                              padding: 10,
                              visibility: getDisplayValue('mute-steve')
                            }}
                            disabled={callId === undefined}>
                              {interview.mute ? (<>
                              {!isMobile &&
                                <>Un-mute Steve</>
                              }
                              </>) : <>
                                {!isMobile &&
                                  <>Steve is interrupting me!</>
                                }
                              </>} 
                            </IconButton>                          
                          }
                        </div>
                      </div>     
                    </Grid>
                  </Grid>
                  {isMobile &&
                  <Grid item xs={12} sm={12}>
                    <div style={{ textAlign: 'left', alignItems: 'left', fontSize: 16, fontFamily: 'Mulish', color: '#181818' }}>
                      {visualState === '' &&
                        <div style={{ color: interviewStarted ? '#181818' : 'black' }}>
                          Steve is Connecting...
                        </div>
                      }
                      {visualState === 'listening' &&
                        <div>
                          Steve is <b>Listening</b>
                        </div>
                      }
                      {visualState === 'speaking' &&
                        <div>
                          Steve is <b>Speaking</b>
                        </div>
                      }                          
                    </div>  
                  </Grid>                      
                  }  
                  <Grid item xs={12} style={{ fontFamily: 'Mulish', fontSize: 20, marginTop: 0, paddingTop: 0 }}>
                    <span id="stage" style={{ visibility: getDisplayValue('stage'), color: 'gray' }}>
                      Stage: {interviewStage}
                    </span>
                  </Grid>                   
                  {tutorialStatus === 'started' &&                   
                    <Frigade.Tour
                      flowId="flow_rlk6j4gk" 
                    />                 
                  }                                  
                  {!isMobile &&
                    <Grid item container style={{ borderRadius: 10, paddingRight: 25, visibility: getDisplayValue('instructions'), paddingTop: 0, marginTop: 0 }}>
                    <>
                      <Grid item xs={emailCaseImage === '' ? 12 : 3} style={{ visibility: getDisplayValue('instructions') }}>
                        <div id="instructions" style={{ display: 'flex', alignItems: 'center' }}>
                          <p style={{ fontFamily: 'Mulish', fontSize: 18 }}>Instructions</p>
                        </div>
                        <br/>
                        {renderContext()}
                        {(initialSubmissionDone || tutorialStatus === 'started') &&
                          <div id="feedback" style={{ visibility: getDisplayValue('feedback'), background: '#4451f6', paddingLeft: 10, paddingRight: 10, borderRadius: 10, paddingBottom: 10 }}>
                            <div style={{ display: 'flex', alignItems: 'center'}}>
                              <p style={{ fontFamily: 'Mulish', fontSize: 18 }}>Feedback</p>
                            </div>
                            <br/>
                            {renderFeedback()}
                            <div key={0} style={{ display: 'flex', alignItems: 'center', paddingRight: 15, marginBottom: 25, maxHeight: 75, height: 75, overflow: 'auto' }}>
                              <Typography variant="body2" sx={{ fontFamily: 'Mulish', color: '#C6C2D0', fontWeight: 700, paddingBottom: 0, marginBottom: 0 }}>
                                Edit and hit the Re-Submit button
                              </Typography>
                            </div>                                                                                            
                            {(loadingDiagramFeedback || emailSubmitPending) && (
                                <div style={{ color: 'white', fontFamily: 'Mulish', display: 'flex', alignItems: 'center', marginBottom: 15 }}>
                                  <CircularProgress size={20} style={{ marginRight: 10 }} /> Updating Feedback...
                                </div>
                              )
                            }                                                                
                          </div>
                        }
                      </Grid>                                        

                    {(emailCaseImage !== '' && ( (interviewType === 'virtual_assistant_tutorial' && interviewStarted && interview.messages.length > 3) || (tutorialStatus === 'started' ) || (interviewType === 'virtual_assistant' || interviewType === 'virtual_assistant_email_case') )) &&
                    <>
                      <Grid item xs={9} style={{ paddingLeft: 30 }}>
                        <img 
                          id="email-case-image"
                          style={{
                            width: '80%', // Adjusted to control the maximum width
                            maxWidth: '80%',
                            visibility: getDisplayValue('email-case-image'),
                            position: 'relative',
                          }}
                          src={emailCaseImage} 
                        />
                      {(tutorialStatus === 'started' || (interview.messages.length > 6 && showWriteSection)) &&
                        <>
                            <br/><br/>
                            <textarea 
                              id='submission'
                              style={{
                                fontFamily: 'Mulish',
                                fontSize: 16,
                                border: '0.5px solid #5F6368',
                                borderRadius: 10,
                                background: 'black',
                                color: 'white',
                                padding: 15, 
                                width: '50%',
                                height: 150,
                                visibility: getDisplayValue('submission'),
                              }}
                              disabled={emailCaseImage === ''}
                              value={emailCaseDraft}
                              onChange={(e) => {setEmailCaseDraft(e.target.value)}}
                              placeholder="Enter email draft here and then click Submit for feedback"
                            />
                            <br/><br/>
                            <Button variant={'contained'} onClick={handleEmailCaseSubmission} 
                              style={{
                                background: '#4451f6',
                                color: 'white',
                                borderRadius: 35,
                                fontFamily: 'Mulish',
                                fontSize: 16,
                                marginRight: 25, // Added to provide spacing between buttons
                                opacity: emailSubmitPending ? 0.5 : 1,
                                visibility: getDisplayValue('submission'),
                                width: 120,
                                textAlign: 'center', 
                              }}
                              disabled={tutorialStatus === 'started' || emailSubmitPending || emailCaseImage === ''}
                            >
                              {emailSubmitPending ? <CircularProgress size={20} style={{ color: 'black', marginRight: 10 }} /> : null}
                              {emailSubmitPending ? <>Looking</> : <>{initialSubmissionDone ? 'Re-Submit' : 'Submit'}</>}
                            </Button>                                                  
                        </>  
                      }
                      </Grid>
                    </>
                    }
                    </>
                  </Grid>
                  }                     
                  {((!isMobile && !interviewStarted && (tutorialStatus === 'not_started' || tutorialStatus === 'complete')) || (isMobile && firstMobileScreenDone && !interviewStarted || (tutorialStatus === 'not_started' || tutorialStatus === 'complete'))) && 
                    <Dialog open={!interviewStarted}>
                      <DialogTitle style={{
                        background: 'black',
                        color: 'white',
                        border: '0.5px solid #5F6368',
                        fontFamily: 'Mulish'
                      }}>{!micEnabled ? "Get Started" : renderModalTitle()}</DialogTitle>
                      <DialogContent style={{
                        background: 'black',
                        color: 'white',
                        border: '0.5px solid #5F6368',
                        padding: 10
                      }}>
                        <DialogContentText>
                          {renderIntroDialoguePostInputsEnabledContent()}
                        </DialogContentText>
                      </DialogContent>
                      <DialogActions style={{ background: 'black', border: '0.5px solid #5F6368', padding: 10 }}>
                        {!micEnabled &&
                          <Button autoFocus onClick={enableMic} style={{ color: 'white', background: '#4451f6', borderRadius: 25, fontFamily: 'Mulish' }}>
                            {micEnabled ? 'Microphone Enabled' : 'Enable Microphone'}
                          </Button>                        
                        }
                        {micEnabled &&
                          <Button onClick={() => {interviewTypeQueryParam === 'virtual_assistant_tutorial' && tutorialStatus === 'not_started' ? handleStartTutorial() : handleStartInterview() }} disabled={!micEnabled} style={{ color: 'white', background: '#4451f6', borderRadius: 25, fontFamily: 'Mulish' }}>
                            {interviewTypeQueryParam === 'virtual_assistant_tutorial' && tutorialStatus === 'not_started' ? 'Start Tutorial' : 'Start Interview'}
                          </Button>                        
                        }
                      </DialogActions>
                    </Dialog>
                  }
                  {endTutorialModalOpen && 
                    <Dialog open={endTutorialModalOpen}>
                      <DialogTitle style={{
                        background: 'black',
                        color: 'white',
                        border: '0.5px solid #5F6368',
                        fontFamily: 'Mulish'
                      }}>Go to the Real Interview</DialogTitle>
                      <DialogContent style={{
                        background: 'black',
                        color: 'white',
                        border: '0.5px solid #5F6368',
                        padding: 10
                      }}>
                        <DialogContentText>
                          {!endTutorialModalOpenFirstBtnClicked &&
                            <p style={{ color: 'white' }}>
                              Thanks for taking the practice interview! 
                              <br/><br/>
                              The real interview will ask you about your background and have a case study that involves analyzing and responding to a situation. You will be graded on your communication skills, reading ability, your written response and how you respond to feedback.                            
                            </p>
                          }
                          {endTutorialModalOpenFirstBtnClicked &&
                            <p style={{ color: 'white' }}>
                              Click "Go" when you are ready to start the real interview.
                            </p>
                          }
                        </DialogContentText>
                      </DialogContent>
                      <DialogActions style={{ background: 'black', border: '0.5px solid #5F6368', padding: 10 }}>
                        {!endTutorialModalOpenFirstBtnClicked &&
                          <Button autoFocus onClick={() => {setEndTutorialModalOpenFirstBtnClicked(true)}} style={{ color: 'white', background: '#4451f6', borderRadius: 25, fontFamily: 'Mulish' }}>
                            I understand
                          </Button>
                        }
                        {endTutorialModalOpenFirstBtnClicked &&
                          <Button autoFocus onClick={() => {window.location.href = `${window.location.origin}?interview_type=virtual_assistant`}} style={{ color: 'white', background: '#4451f6', borderRadius: 25, fontFamily: 'Mulish' }}>
                            Go
                          </Button>
                        }                                   
                      </DialogActions>
                    </Dialog>
                  }                  
                  {/* {timer <= 0 && 
                    <Dialog open={timer <= 0}>
                      <DialogTitle>Nice Work!</DialogTitle>
                      <DialogContent>
                        <DialogContentText>
                          Thank you so much for trying the experience. We will send you a recording of your experience and a scorecard to your email.
                        </DialogContentText>
                        <TextField
                          autoFocus
                          margin="dense"
                          id="name"
                          label="Email Address"
                          type="email"
                          fullWidth
                          onChange={handleEmailChange}
                          variant="standard"
                        />                        
                      </DialogContent>
                      <DialogActions>
                        <Button onClick={handleJoinWaitlist}>
                          Enter Email
                        </Button>
                      </DialogActions>
                    </Dialog>
                  } */}
              </Grid>              
          </Container>
          {scorecardModalOpen && (
            <Dialog
              style={{
                background: 'black',
                color: 'white',
                border: '0.5px solid #5F6368',
                fontFamily: 'Mulish'                  
              }}                          
              open={scorecardModalOpen}
              onClose={() => setScorecardModalOpen(false)}
              aria-labelledby="scorecard-dialog-title"
              aria-describedby="scorecard-dialog-description"
              fullWidth
              maxWidth="md" // Changed from default to make the dialog wider
            >
              <DialogTitle id="scorecard-dialog-title" 
                style={{
                  background: 'black',
                  color: 'white',
                  border: '0.5px solid #5F6368',
                  fontFamily: 'Mulish'                  
                }}                            
              >Interview Scorecard</DialogTitle>
              <DialogContent
                style={{
                  background: 'black',
                  color: 'white',
                  border: '0.5px solid #5F6368',
                  fontFamily: 'Mulish'                  
                }}              
              >
                <DialogContentText id="scorecard-dialog-description">
                  Here's how you did in your interview.
                </DialogContentText>
                <List>
                  <TableContainer component={Paper} 
                    style={{
                      background: 'black',
                      color: 'white',
                      border: '0.5px solid #5F6368',
                      fontFamily: 'Mulish'                  
                    }}                                
                  >
                    <Table aria-label="scorecard table">
                      <TableHead style={{
                        background: 'black',
                        color: 'white',
                        border: '0.5px solid #5F6368',
                        fontFamily: 'Mulish'                               
                      }}>
                        <TableRow style={{
                          background: 'black',
                          color: 'white',
                          border: '0.5px solid #5F6368',
                          fontFamily: 'Mulish'                                 
                        }}>
                          <TableCell style={{ color: 'white', fontFamily: 'Mulish'}}>Signal</TableCell>
                          <TableCell style={{ color: 'white', fontFamily: 'Mulish'}} align="left">Feedback</TableCell>
                          <TableCell style={{ color: 'white', fontFamily: 'Mulish'}} align="left">Score</TableCell>
                          <TableCell style={{ color: 'white', fontFamily: 'Mulish'}} align="left">Discussed in Interview</TableCell>                          
                        </TableRow>
                      </TableHead>
                      <TableBody style={{
                        background: 'black',
                        color: 'white',
                        border: '0.5px solid #5F6368',
                        fontFamily: 'Mulish'                               
                      }}>
                        {interview.scorecard && interview.scorecard.length === 0 &&
                        <TableRow style={{
                          background: 'black',
                          color: 'white',
                          border: '0.5px solid #5F6368',
                          fontFamily: 'Mulish'                        
                        }}>
                          <TableCell colSpan={4} align="center">
                            <CircularProgress style={{ color: 'white' }} />
                          </TableCell>
                        </TableRow>
                        }
                        {interview.scorecard && interview.scorecard.length > 0 &&
                          <>
                            {interview.scorecard.map((row) => (
                              <TableRow key={row.signal} style={{
                                background: 'black',
                                color: 'white',
                                border: '0.5px solid #5F6368',
                                fontFamily: 'Mulish'                                       
                              }}>
                                <TableCell style={{ color: 'white', fontFamily: 'Mulish' }} component="th" scope="row">
                                  {row.signal}
                                </TableCell>
                                <TableCell style={{ color: 'white', fontFamily: 'Mulish' }} align="left">{row.feedback}</TableCell>
                                <TableCell style={{ color: 'white', fontFamily: 'Mulish' }} align="left">{row.asked_to_candidate === false ? <>-</> : row.score > 7 ? 'Yes' : 'No'}</TableCell>
                                <TableCell style={{ color: 'white', fontFamily: 'Mulish' }} align="left">{String(row.asked_to_candidate).charAt(0).toUpperCase() + String(row.asked_to_candidate).slice(1)}</TableCell>                                
                              </TableRow>
                            ))}                        
                          </>
                        }
                      </TableBody>
                    </Table>
                  </TableContainer>
                </List>   
                {/* <br/><br/>
                <p style={{ color: 'white', fontFamily: 'Mulish', fontWeight: 600 }}>Join the waitlist to get your recording. </p>    
                <TextField
                  InputLabelProps={{
                    style: { color: 'white', fontFamily: 'Mulish' },
                  }}
                  inputProps={{
                    style: { color: 'white', fontFamily: 'Mulish' },
                  }}    
                  InputProps={{
                    style: { color: 'white', borderBottom: '1px solid white' },
                  }}                          
                  autoFocus
                  margin="dense"
                  id="name"
                  label="Enter your email"
                  type="email"
                  fullWidth
                  onChange={handleEmailChange}
                  variant="standard"
                />                                */}
              </DialogContent>
              {/* <DialogActions
                style={{
                  background: 'black',
                  color: 'white',
                  border: '0.5px solid #5F6368',
                  fontFamily: 'Mulish'                  
                }}              
              >
                <Button style={{ color: 'white', fontFamily: 'Mulish'}} onClick={handleJoinWaitlist}>
                  Submit
                </Button>    
              </DialogActions>  */}
            </Dialog>
          )}
          {firstMobileScreenDone && isMobile &&
              <Grid item container xs={12} sm={12} style={{ padding: 10, margin: '0 auto', borderRadius: 10, display: 'flex', alignItems: 'center' }}>
                <p style={{ fontFamily: 'Mulish', fontSize: 18 }}>Instructions</p>
                <br/><br/><br/>
                {renderContext()}                                     
              </Grid>
          }         
          {!firstMobileScreenDone && isMobile && 
            <Grid item container xs={12} sm={12} style={{ padding: 10, margin: '0 auto',borderRadius: 10, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <div style={{ marginBottom: 80, border: '0.5px solid #5F6368', borderRadius: 10 }}>
                <iframe width="290px" height="203px" src="https://www.youtube.com/embed/MzvYtFLs9Tc?si=hVH8J-pIEztrLzOh" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>                   
              </div>
              <button style={{ color: 'black', background: '#19E539', border: '0 none', borderRadius: 25, fontFamily: 'Mulish', fontSize: 16, width: '80%', height: 48 }} onClick={() => {setFirstMobileScreenDone(true)}}>
                Start Interview
              </button>
              <br/>
              <br/>      
              <br/>
              <br/>      
              {/* <button style={{ color: 'white', background: 'black', border: '1px solid white', borderRadius: 25, fontFamily: 'Mulish', fontSize: 16, width: '80%', height: 48 }} onClick={handleOpenWaitlist}>
                Join Waitlist
              </button>                    */}
            </Grid>    
          }    
          <Container>
            <Snackbar
              open={subscriptionSuccessSnackOpen}
              autoHideDuration={5000}
              onClose={handleCloseSubscriptionFailSnack}>
                <Alert onClose={handleCloseFailSnack} severity="error" sx={{ width: '100%' }}>
                  Error: e-mail already used or invalid format.
                </Alert>
              </Snackbar>
            </Container>
        </>
    );
};

export default App;
